@import "../../../styles/variables"

.tabs
  display: inline-flex
  justify-content: center
  align-items: flex-start

.tab
  width: 150px
  height: 50px
  margin-bottom: -1px

.btn
  width: 100%
  height: 100%
  background-color: $white
  font-family: "IBMPlex Sans", sans-serif
  font-size: 16px
  border-bottom: 1px solid $primary
  border-left: 1px solid $black-accent
  border-right: 1px solid $black-accent
  border-top: 3px solid $black-accent
  border-top-left-radius: 5px
  border-top-right-radius: 5px
  color: $black-accent

.active
  button
    border-color: $primary
    border-bottom: 1px solid $white
    color: $primary

.body
  width: 100%
  min-height: 30px
  padding: 20px
  border: 1px solid $primary
  border-radius: 0 5px 5px 5px
  background-color: $white

@media(max-width: 640px)
  .tab
    width: 110px

  .btn
    font-size: 14px

@media(max-width: 480px)
  .tab
    width: 80px
    height: 40px

  .btn
    font-size: 12px

@media(max-width: 370px)
  .tab
    width: 70px
    height: 40px

  .btn
    font-size: 10px

  .body
    border-top-right-radius: 0