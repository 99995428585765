.home
  &_banner
    position: relative
    @include size($y: 550px)

    &--wrapper
      position: absolute
      top: 0
      z-index: 0
      @include size

      &:after
        @include size
        content: ""
        position: absolute
        top: 0
        left: 0
        z-index: 1
        background: rgba($black, 0.5)
        backdrop-filter: blur(4px)


    &--img
      @include size
      @include img

    &--info
      @include flex($direction: column)
      @include size
      position: relative
      z-index: 5
      color: $white

    &--line
      width: 100%
      height: 4px
      margin: 10px 0
      background: $white
      box-shadow: 1px 1px 3px $black

    &--menu
      @include flex($direction: column)

  &_menu
    @include flex
    flex-wrap: wrap
    margin-bottom: 40px

    &--item
      padding: 10px

    &--link
      font-size: 18px
      color: rgba($white, 0.8)
      transition: $time

      &:hover
        color: $white



