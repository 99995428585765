.error
  &-wrapper
    display: flex

  &-container
    display: flex
    align-items: center
    justify-content: center
    height: 100%
    margin: auto

  &-content
    display: flex
    align-items: center
    flex-direction: column

  &-text
    font-family: "IBMPlex Sans", sans-serif
    font-size: 38px
    color: $primary

  &-btn
    margin-top: 40px