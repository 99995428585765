.header
  background: $black

  &-wrapper
    @include size($y: 150px)
    @include flex($jc: space-between, $align: flex-start)

  &-content
    @include flex($direction: column, $jc: space-between)
    @include size
    padding-left: 22px
    padding-bottom: 20px

  &-list
    @include flex($jc: space-between)
    width: 100%

    &.underline
      padding-bottom: 6px
      border-bottom: 1px solid rgba($white, 0.7)

@media (max-width: 910px)
  .header
    &-content
      padding-bottom: 0
      align-items: flex-end
      justify-content: center
    &-list
      display: none
    &-wrapper
      height: 100px
