.nav
  padding-top: 15px

  &_list
    @include flex
    gap: 15px
    font-size: 17px
    text-transform: uppercase
    font-family: 'OpenSans', sans-serif

    &--link
      position: relative
      padding: 8px 12px
      color: $white
      border-radius: 5px
      transition: $time

      &::after
        content: ''
        position: absolute
        width: 100%
        transform: scaleX(0)
        height: 2px
        bottom: 0
        left: 0
        background-color: $primary
        transform-origin: bottom right
        transition: transform 0.3s ease-out

      &:hover::after
        transform: scaleX(1)
        transform-origin: bottom left

      &.active
        &::after
          transform: scaleX(1)
          transform-origin: bottom left

@media(max-width: 1030px)
  .nav
    &_list
      font-size: 14px
