@font-face
  font-family: 'OpenSans'
  src: url('../assets/fonts/OpenSans/Regular/OpenSans-Regular.eot?#iefix') format('embedded-opentype'), url('../assets/fonts/OpenSans/Regular/OpenSans-Regular.woff2') format('woff2'), url('../assets/fonts/OpenSans/Regular/OpenSans-Regular.woff') format('woff'), url('../assets/fonts/OpenSans/Regular/OpenSans-Regular.ttf') format('truetype')
  font-weight: 400
  font-style: normal

@font-face
  font-family: 'OpenSans Italic'
  src: url('../assets/fonts/OpenSans/RegularItalic/OpenSans-Italic.eot?#iefix') format('embedded-opentype'), url('../assets/fonts/OpenSans/RegularItalic/OpenSans-Italic.woff2') format('woff2'), url('../assets/fonts/OpenSans/RegularItalic/OpenSans-Italic.woff') format('woff'), url('../assets/fonts/OpenSans/RegularItalic/OpenSans-Italic.ttf') format('truetype')
  font-weight: 400
  font-style: italic

@font-face
  font-family: 'OpenSans SemiBold'
  src: url('../assets/fonts/OpenSans/SemiBold/OpenSans-SemiBold.eot?#iefix') format('embedded-opentype'), url('../assets/fonts/OpenSans/SemiBold/OpenSans-SemiBold.woff2') format('woff2'), url('../assets/fonts/OpenSans/SemiBold/OpenSans-SemiBold.woff') format('woff'), url('../assets/fonts/OpenSans/SemiBold/OpenSans-SemiBold.ttf') format('truetype')
  font-weight: 400
  font-style: italic

@font-face
  font-family: 'OpenSans ExtraBold'
  src: url('../assets/fonts/OpenSans/ExtraBold/OpenSans-ExtraBold.eot?#iefix') format('embedded-opentype'), url('../assets/fonts/OpenSans/ExtraBold/OpenSans-ExtraBold.woff2') format('woff2'), url('../assets/fonts/OpenSans/ExtraBold/OpenSans-ExtraBold.woff') format('woff'), url('../assets/fonts/OpenSans/ExtraBold/OpenSans-ExtraBold.ttf') format('truetype')
  font-weight: 900
  font-style: normal

@font-face
  font-family: 'IBMPlex Sans'
  src: url('../assets/fonts/IBMPlexSans/SemiBold/IBMPlexSans-SemiBold.eot?#iefix') format('embedded-opentype'), url('../assets/fonts/IBMPlexSans/SemiBold/IBMPlexSans-SemiBold.woff2') format('woff2'), url('../assets/fonts/IBMPlexSans/SemiBold/IBMPlexSans-SemiBold.woff') format('woff'), url('../assets/fonts/IBMPlexSans/SemiBold/IBMPlexSans-SemiBold.ttf') format('truetype')
  font-weight: 600
  font-style: normal