@import "../../../styles/variables"

.list
  display: flex
  align-items: center
  justify-content: space-evenly
  flex-wrap: wrap
  padding: 50px 0 140px
  gap: 20px

.card
  display: flex
  align-items: center
  flex-direction: column
  flex-shrink: 0
  width: 250px
  height: 250px
  border: 2px solid $primary
  border-radius: 5px
  padding: 20px
  text-align: center

.imgWrapper
  flex-shrink: 0
  width: 100px
  height: 100px
  padding: 20px
  border-radius: 50%
  background-color: $primary

.img
  width: 100%
  height: 100%

.title
  border-top: 1px solid $primary
  margin-top: 20px
  display: flex
  align-items: center
  justify-content: center
  height: 100%
  text-transform: uppercase
  color: $black
