@import "../../styles/variables"

.list
  display: grid
  grid-template-columns: 1fr 1fr 1fr
  grid-gap: 30px
  margin-bottom: 120px

.card
  width: 100%
  height: 320px
  padding: 15px
  border: 1px solid $red
  overflow: hidden
  transition: 0.3s
  cursor: pointer
  &:hover
    background-color: darken($white, 2%)


.link
  width: 100%
  height: 100%

.img
  width: 100%
  height: 200px
  overflow: hidden
  object-fit: cover

.name
  padding: 10px 0
  font-family: "IBMPlex Sans", sans-serif
  font-size: 18px
  color: $primary

@media (max-width: 992px)
  .list
    grid-template-columns: 1fr 1fr

@media (max-width: 620px)
  .list
    grid-template-columns: 1fr