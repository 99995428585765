@import "../../../styles/variables"

.wrapper
  position: fixed
  top: 0
  left: 0
  z-index: 60
  width: 100vw
  height: 100vh
  background: rgba($black, 0.3)
  backdrop-filter: blur(3px)

.menu
  display: flex
  flex-direction: column
  width: 270px
  height: 100%
  background-color: $black
  overflow-y: auto
  animation: openModal
  animation-duration: 0.3s

.flex
  display: flex
  justify-content: space-between
  align-items: flex-end
  padding: 30px 10px 6px 14px
  border-bottom: 1px solid $white

.header
  color: $white
  font-size: 16px

.nav
  padding-left: 30px

.navItem
  height: 50px
  border-bottom: 1px solid rgba($white, 0.2)

.link
  width: 100%
  height: 100%
  display: flex
  align-items: center
  justify-content: flex-start
  color: $white

.title
  margin-top: 28px
  font-size: 15px
  text-align: center
  color: $white

.info
  display: flex
  height: 100%
  padding: 30px 20px 0 30px
  justify-content: flex-end
  align-items: flex-start
  flex-direction: column
  margin-bottom: 30px


.infoLink
  display: inline-block
  padding: 8px 0
  color: $white
  font-size: 14px

  & > img
    height: 18px
    margin-right: 10px

.footer
  padding: 8px
  text-align: center
  color: rgba($white, 0.9)
  font-size: 10px

.networks
  display: flex
  align-items: center
  justify-content: space-between
  padding: 30px

.networksImg
  width: 50px
  height: 50px
  border-radius: 10px

@keyframes openModal
  0%
    margin-left: -100%

  100%
    margin-left: 0