.header
  text-transform: uppercase
  margin: 20px 0 15px
  font-size: 18px

.list
  list-style-type: decimal
  margin-left: 40px

.item
  margin: 5px 0
  font-size: 16px
