.form
  @include size
  @include flex($direction: column)

  &_item
    position: relative
    padding: 20px

    &--label
      position: absolute
      top: -3px

    &--input
      @include size($x: 450px, $y: 45px)
      padding: 15px
      border-radius: 5px
      font-size: 20px
      caret-color: $primary

    &--error
      position: absolute
      bottom: 4px
      font-size: 13px
      font-weight: bold
      color: $primary

  &_btn
    &--apply
      margin-top: 20px

@media (max-width: 640px)
  .form
    &_item
      width: 100%
      &--input
        width: 100%