.footer
  &_map
    &--frame
      @include size($y: 160px)
      border: none

@media(max-width: 756px)
  .footer
    &_map
      display: none
