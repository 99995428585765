.wrapper
  display: flex
  align-items: center
  justify-content: center
  flex-direction: column
  height: 100%

.container
  display: flex
  align-items: center
  justify-content: center
  flex-direction: column
  height: 100%

.form
  display: flex
  align-items: center
  justify-content: center
  flex-direction: column
  padding-bottom: 30px

.label
  display: flex
  align-items: flex-start
  justify-content: center
  flex-direction: column

.input
  width: 275px
  height: 45px