.contacts
  &_info
    @include flex($jc: space-around, $align: flex-start)
    padding: 70px 0

    &--list
      max-width: 250px

    &--item
      @include flex($direction: column)
      position: relative
      width: 100%

      &:after
        content: ""
        position: absolute
        top: 30px
        left: calc(50% + 30px)
        display: block
        height: 2px
        width: 100%
        background-color: $primary

      &:nth-child(2)
        .contacts_info--icon
          @include size($x: 30px)

      &:last-child
        &:after
          content: none

    &--iconWrapper
      @include size($x: 60px, $y: 60px)
      @include flex
      margin-bottom: 30px
      background-color: $primary
      border-radius: 50%

    &--icon
      @include size($x: 35px)

    &--linkWrapper
      text-align: center
      padding: 3px 0
      margin-bottom: 4px

    &--link
      font-size: 18px
      font-family: "OpenSans SemiBold", sans-serif
      color: $black
      border-bottom: 1px solid $primary
      transition: $time

      &:hover
        color: rgba($primary, 0.9)

@media(max-width: 678px)
  .contacts
    &_listIcon
      flex-direction: column
      & > .contacts_info--item
        margin-bottom: 20px
        &:after
          content: none
