.header
  &_contacts
    @include flex($align: flex-end)
    gap: 25px
    padding-top: 5px

    &--link
      @include flex
      font-size: 13px
      color: $white

      & > img
        margin-right: 3px

    &--item
      @include flex

      &:first-child
        & img
          @include size($x: 24px, $y: 24px)
          padding-bottom: 3px

      &:nth-child(2)
        & img
          padding-right: 1px
          @include size($x: 22px, $y: 27px)

      &:nth-child(3)
        & img
          @include size($x: 18px, $y: 24px)
          padding-right: 2px
          padding-bottom: 2px

      &:last-child
        & img
          padding-right: 1px
          @include size($x: 20px, $y: 22px)
          padding-bottom: 1px

@media(max-width: 1160px)
  .header
    &_contacts
      &--link
        font-size: 10px

      &--item
        &:first-child
          & img
            @include size($x: 17px, $y: 17px)

        &:nth-child(2)
          & img
            @include size($x: 15px, $y: 17px)

        &:nth-child(3)
          & img
            @include size($x: 12px, $y: 14px)

        &:last-child
          & img
            @include size($x: 12px, $y: 14px)

@media(max-width: 960px)
  .header
    &_contacts
      &--link
        font-size: 9px