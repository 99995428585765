.lds-ring
  @include flex
  position: absolute
  top: 0
  left: 0
  right: 0
  bottom: 0

.lds-ring div
  box-sizing: border-box
  display: block
  position: absolute
  width: 250px
  height: 250px
  margin: 8px
  border: 30px solid black
  border-radius: 50%
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite
  border-color: black transparent transparent transparent

.lds-ring div:nth-child(1)
  animation-delay: -0.45s

.lds-ring div:nth-child(2)
  animation-delay: -0.3s

.lds-ring div:nth-child(3)
  animation-delay: -0.15s

@keyframes lds-ring
  0%
    transform: rotate(0deg)

  100%
    transform: rotate(360deg)

