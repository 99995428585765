@import "../../../styles/variables"

.banner
    width: 100%
    height: 350px
    position: relative
    background-color: $black
    overflow: hidden

    &:after
        width: 100%
        height: 100%
        content: ""
        position: absolute
        top: 0
        left: 0
        z-index: 1
        background: rgba($black, 0.3)
        backdrop-filter: blur(2px)

.container
    position: relative
    z-index: 5
    display: flex
    align-items: center
    justify-content: center
    height: 100%

.img
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
    object-fit: cover

@media (max-width: 576px)
    .banner
        height: 150px

    .header
        font-size: 38px