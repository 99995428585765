@import "../../styles/variables"

.wrapperCl
  display: flex
  align-items: center
  justify-content: center
  flex-direction: column
  text-align: center
  padding-bottom: 70px
  margin-top: 30px

.wrapperRw
  width: 100%
  display: flex
  align-items: center
  justify-content: space-around
  text-align: center
  padding-bottom: 70px
  margin-top: 30px

.content
  padding: 0 50px

.header
  font-size: 36px

.text
  padding: 25px 0 35px
  font-size: 24px

.gray
  color: $primary

@media (max-width: 886px)
  .wrapperRw
    flex-direction: column

  .imgRw
    width: 300px

@media (max-width: 330px)
  .imgRw
    width: 240px