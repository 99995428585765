@import "../../../styles/variables"

.wrapper
  width: 100%
  display: flex
  align-items: center
  flex-direction: column
  text-align: center


.content
  width: 100%
  display: flex
  justify-content: space-between
  margin-bottom: 80px
  gap: 30px

.list
  position: relative
  width: 50%
  display: flex
  flex-direction: column
  padding: 30px
  gap: 30px

.listRed
  background-color: rgba($black, 0.1)
  border-radius: 20px

.title
  font-family: "IBMPlex Sans", sans-serif
  font-size: 26px
  color: rgb(255, 161, 161)

.titleRed
  color: $black

.titleGreen
  color: green

.item
  display: flex
  align-items: center

.imgWrapper
  width: 40px
  height: 40px
  border-radius: 50%
  margin-right: 15px
  flex-shrink: 0

.imgWrapperRed
  background: $black

.imgWrapperGreen
  background: green

.icon
  width: 100%
  height: 100%

.arrowWrapper
  position: absolute
  top: 0
  right: 5%
  width: 20%
  height: 100%
  display: flex
  align-items: center
  justify-content: center

.arrow
  width: 100%
  height: 50%

.subtitle
  font-size: 18px
  max-width: 550px
  text-align: center
  margin-bottom: 70px

@media(max-width: 868px)
  .content
    flex-direction: column
    gap: 100px

  .arrowWrapper
    top: auto
    right: auto
    width: 100%

  .arrow
    position: absolute
    bottom: -155px
    width: auto
    transform: rotate(90deg)

  .list
    width: 100%
    padding: 10px

  .text
    text-align: left



