.modal
  @include size
  @include flex
  position: fixed
  top: 0
  z-index: 55
  background: rgba($black, 0.6)
  backdrop-filter: blur(3px)

  &_wrapper
    @include size($x: 600px, $y: 500px)
    @include flex($jc: space-between)
    position: relative
    padding: 40px
    background-color: $white
    border-radius: 10px
    box-shadow: 0 0 8px $black
    animation-name: modalOpen
    animation-duration: $time

    &.close
      transform: scale(0.5)
      animation-name: modalClose


  &_btn
    &--close
      @include size($x: 30px, $y: 30px)
      position: absolute
      top: 5px
      right: 5px
      border: none
      background: none
      transition: 0.3s

      &:hover
        transform: scale(1.15)

      &-img
        @include size

  &_content
    @include size
    @include flex($direction: column)

  &_header
    border-bottom: 1px solid $primary

@keyframes modalOpen
  from
    transform: scale(0.5)
  to
    transform: scale(1)

@keyframes modalClose
  from
    transform: scale(1)
  to
    transform: scale(0.5)

@media (max-width: 640px)
  .modal
    z-index: 70

    &_wrapper
      @include size
      padding: 90px 20px
      border-radius: 0
