.footer
  &_icons
    @include flex($jc: flex-start)
    @include size

    &--item
      @include size($x: 35px, $y: 35px)
      margin-right: 15px
      & img
        border-radius: 5px

      &:last-child
        margin-right: 0

@media(max-width: 756px)
  .footer
    &_icons
      justify-content: center
      margin-top: 20px