@import "../../../styles/variables"

.menu
  position: fixed
  z-index: 65
  width: 40px
  height: 40px
  padding: 7px
  border-radius: 5px
  display: none
  align-items: center
  justify-content: center
  flex-direction: column
  border: none
  background: rgba($black, 0.3)

  & span
    width: 100%
    height: 3px
    background: $white
    margin-bottom: 10px
    border-radius: 5px
    transition: $time

    &:last-child
      margin-bottom: 0

.active
  background: none

  & span
    &:first-child
      width: 35px
      transform-origin: right
      transform: rotate(-45deg)

    &:nth-child(2)
      width: 0

    &:last-child
      width: 35px
      margin-top: 2.35px
      transform-origin: right
      transform: rotate(45deg)

@media(max-width: 910px)
  .menu
    display: flex