.footer
  &_nav
    @include flex($direction: column, $align: flex-start, $jc: flex-start)
    padding: 0 5px

    &--item
      padding: 3px 0

    &--link
      font-size: 16px
      line-height: 0
      color: rgba($white, 0.8)
      transition: $time

      &:hover
        color: $white

@media(max-width: 756px)
  .footer
    &_nav
      &-links
        display: none

      &-contacts
        align-items: center