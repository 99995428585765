@import "../../styles/variables"

.container
  display: flex
  justify-content: space-between
  flex-direction: column
  padding: 50px 0
  overflow: hidden

.wrapper
  display: flex
  align-items: flex-start
  justify-content: space-between
  overflow: hidden

.content
  width: 100%
  margin-left: 20px

.list
  font-family: "Open Sans", sans-serif
  list-style: disc
  margin-left: 50px
  font-size: 18px
  line-height: 145%

  & > li
    margin-bottom: 5px

.listNum
  font-family: "Open Sans", sans-serif
  list-style: decimal
  margin-left: 50px
  font-size: 18px
  line-height: 145%

  & > li
    margin-bottom: 10px

.tableWrapper
  overflow-x: auto
  -ms-overflow-style: none
  scrollbar-width: none

  &::-webkit-scrollbar
    width: 0
    height: 0

.table
  min-width: 520px
  width: 100%
  font-size: 18px
  line-height: 145%
  margin: 10px 0 20px
  border: 2px solid rgba(#151716, 20%)
  border-collapse: collapse

  & tr
    border: 2px solid rgba(#151716, 20%)

  & th
    background-color: rgba(#151716, 5%)

  & td, th
    padding: 10px
    border: 2px solid rgba(#151716, 20%)

  &_stroke1
    width: 50%


.cards
  margin: 50px 0
  display: flex
  justify-content: space-evenly
  flex-wrap: wrap
  gap: 20px

.g40
  gap: 40px

.card
  display: flex
  align-items: center
  justify-content: space-between
  flex-direction: column
  width: 224px
  height: 250px
  padding: 20px
  border: 1px solid $red
  box-shadow: 4px 4px 5px rgba($black, 0.2)

.text
  font-family: "Open Sans", sans-serif
  text-indent: 10px
  font-size: 18px
  line-height: 145%

.textSm
  font-family: "Open Sans", sans-serif
  font-size: 16px
  line-height: 145%
  font-weight: 700
  color: darken(#9e0407, 5%)
  text-align: center

.text1
  font-family: "Open Sans", sans-serif
  font-size: 18px
  line-height: 145%

.text2
  font-family: "Open Sans", sans-serif
  font-size: 18px
  line-height: 145%
  font-weight: 700
  color: darken(#9e0407, 5%)

.text3
  font-family: "Open Sans", sans-serif
  font-size: 18px
  line-height: 145%
  font-weight: 700
  color: $primary
  text-decoration: underline

.textLg
  font-size: 20px

.title
  font-family: "OpenSans SemiBold", sans-serif
  font-size: 22px
  color: darken(#9e0407, 5%)
  margin: 35px 0 25px

.titleCard
  font-family: "IBMPlex Sans", sans-serif
  font-size: 20px
  text-align: center
  text-transform: uppercase
  color: $primary
  height: 52px
  overflow: hidden

.titleCardFull
  font-family: "IBMPlex Sans", sans-serif
  font-size: 18px
  text-align: center
  text-transform: uppercase
  color: $primary

.bannerServ
  display: flex
  align-items: center
  justify-content: center
  width: 100%
  padding: 30px
  margin: 20px 0 0 0
  border: 1px solid $red
  box-shadow: 4px 4px 5px rgba($black, 0.2)

  & > h6
    font-family: "IBMPlex Sans", sans-serif
    font-size: 20px
    text-align: center
    text-transform: uppercase
    color: $red

.mb25
  margin-bottom: 25px

.mt0
  margin-top: 0

@media (max-width: 920px)
  .content
    margin-left: 0

@media (max-width: 580px)
  .tableWrapper
    padding-right: 20px

