.btn-small
  padding: 10px 3px 0 3px
  border: none
  font-size: 14px
  text-transform: uppercase
  color: $white
  background: none
  display: none

  &.active
    display: inline

