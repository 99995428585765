@import "../../../styles/variables"

.header
  padding: 15px 0
  margin-bottom: 10px
  border-bottom: 1px solid $primary

.wrapper
  display: inline-block
  width: 300px
  height: max-content
  flex-shrink: 0
  margin-right: 10px
  padding: 0 20px 20px 20px
  border: 1px solid $black-accent
  border-radius: 5px
  background-color: $white1

.itemFlex
  display: flex
  align-items: center
  justify-content: space-between
  padding: 10px 0
  border-bottom: 1px solid $black-accent

.item
  display: list-item
  width: 100%
  padding: 10px 0
  border-bottom: 1px solid $black-accent

  &::marker
    color: $primary
    margin-right: 8px !important

.link
  width: 100%
  color: $black
  font-size: 14px

.button
  width: 90px
  height: 100%
  background: none
  border: none

.icon
  width: 15px
  transform: rotate(90deg)
  transition: $time

.list
  list-style-type: disc

.underList
  display: none
  flex-direction: column
  padding: 8px 0 5px 25px
  list-style-type: disc
  transition: $time

.active
  & svg
    transform: rotate(180deg)
    fill: $primary

  & ol
    display: flex

@media (max-width: 920px)
  .wrapper
    display: none