@import "../../../styles/variables"

.wrapper
  padding: 0 10px 50px 10px
  display: flex
  align-items: center
  justify-content: center
  flex-direction: column
  border-radius: 10px
  background-color: rgba($black, 0.05)

.header
  margin-bottom: 40px

.list
  width: 100%
  display: flex
  align-items: flex-start
  justify-content: space-evenly
  flex-wrap: wrap
  gap: 20px
  margin-bottom: 70px

.item
  display: flex
  align-items: center
  flex-direction: column
  text-align: center
  flex-shrink: 0

.imgWrapper
  display: flex
  align-items: center
  justify-content: center
  width: 80px
  height: 80px
  padding: 20px
  margin-bottom: 20px
  border-radius: 50%
  background-color: $primary

.icon
  width: 100%
  height: 100%

.text
  max-width: 240px

@media(max-width: 568px)
  .list
    gap: 50px