.btn-apply
  display: inline-flex
  min-height: 45px
  min-width: 175px
  padding: 0 15px
  align-items: center
  justify-content: center
  background: $red
  border: none
  border-radius: 3px
  color: $white
  font-size: 16px
  font-family: 'OpenSans', sans-serif
  transition: 0.3s
  flex-shrink: 0

  &:hover
    background: lighten($red, 5%)