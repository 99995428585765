@import "../../../styles/variables"

.wrapper
  position: relative
  padding-bottom: 90px

.btnRound
  position: absolute
  bottom: 10px
  right: 10px
  width: 60px
  height: 60px
  background-color: $primary
  border: none
  border-radius: 50%
  color: $white

.list
  display: flex
  align-items: center
  justify-content: space-evenly
  flex-wrap: wrap
  gap: 10px

.card
  position: relative
  display: flex
  align-items: flex-start
  justify-content: space-between
  flex-direction: column
  width: 290px
  height: 400px
  flex-shrink: 0
  padding: 20px
  border-radius: 10px
  border: 1px solid $primary

.title
  font-weight: bold
  display: block
  padding: 15px 0 5px
  font-size: 18px

.content
  width: 100%
  height: 100%

@media(max-width: 565px)
  .list
    gap: 20px