@mixin flex($jc: center, $align: center, $direction: row)
  display: flex
  justify-content: $jc
  align-items: $align
  flex-direction: $direction

@mixin size($x: 100%, $y: 100%)
  width: $x
  height: $y

@mixin img
  overflow: hidden
  object-fit: cover
  object-position: center