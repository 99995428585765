$black: #151716
$black-accent: rgba($black, 0.3)
$white: #f7f7f7
$white1: #ffffff
$primary: #275668
$red: #9e0407
$hoverAccent: #686C6D
$green-dark: #275668

$time: 0.2s

