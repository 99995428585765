.footer
  background: $black

  &_container
    @include flex($direction: column)
    width: 100%
    padding: 30px 0 0

  &_wrapper
    display: grid
    grid-template-columns: 1fr 1fr 1fr 1.5fr
    width: 100%

  &_header
    display: inline-block
    padding-bottom: 5px
    margin-bottom: 10px
    font-family: "IBMPlex Sans", sans-serif
    font-size: 18px
    text-transform: uppercase
    color: $white
    border-bottom: 2px solid $primary

  &_logoImg
    width: 105px

  &_arrow
    position: fixed
    right: 50px
    bottom: 6vh
    width: 50px
    height: 50px
    padding: 10px
    border: none
    border-radius: 50%
    background-color: $primary
    opacity: 0
    transition: $time

    &:hover
      background-color: lighten($primary, 10%)

    &.active
      opacity: 1

    &--img
      width: 100%
      height: 100%


@media(max-width: 1240px)
  .footer
    padding: 0 20px

    &_logo
      display: none

    &_wrapper
      grid-template-columns: 1fr 1fr 1fr

@media(max-width: 756px)
  .footer
    &_logo
      display: inline-block

    &_wrapper
      grid-template-columns: 1fr 1fr

    &_arrow
      right: 10px
      bottom: 10px

@media (max-width: 340px)
  .footer
    &_logo
      display: none

    &_wrapper
      grid-template-columns: 1fr