.contacts
  position: relative
  overflow: hidden

  &:before
    content: ""
    position: absolute
    top: 220px
    left: 0
    background-image: url("../../../assets/img/geometry.png")
    background-repeat: no-repeat
    width: 550px
    height: 550px
    background-size: cover
    transform: rotate(-90deg)

  &:after
    content: ""
    position: absolute
    bottom: -350px
    right: -300px
    background-image: url("../../../assets/img/geometry.png")
    background-repeat: no-repeat
    width: 550px
    height: 550px
    background-size: cover
    transform: rotate(-45deg)
