*, *:after, *:before
  box-sizing: border-box
  scroll-behavior: smooth

body
  margin: 0
  font-family: -apple-system, BlinkMacSystemFont, 'OpenSans', sans-serif
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
  overflow-y: auto
  scrollbar-color: #9e0407
  scrollbar-width: thin

  &::-webkit-scrollbar
    width: 8px
    background-color: #9b9b9b

  &::-webkit-scrollbar-thumb
    background-color: #9e0407


code
  font-family: source-code-pro, 'OpenSans', monospace

ul, ol
  list-style: none
  padding: 0
  margin: 0

strong
  font-weight: normal

a
  text-decoration: none
  cursor: pointer

button
  padding: 0
  margin: 0
  cursor: pointer

h1, h2, h3, h4, h5, h6, p
  margin: 0
  padding: 0

hr
  margin: 0
  padding: 0

input
  outline: none

input::-webkit-outer-spin-button, input::-webkit-inner-spin-button
  -webkit-appearance: none
  margin: 0


input[type=number]
  -moz-appearance: textfield
