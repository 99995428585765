@import "../../../styles/variables"

.content
  display: flex
  justify-content: space-between
  margin-top: 50px
  height: 100%

.map
  position: relative
  width: 65%
  display: flex
  align-items: center
  justify-content: center

.img
  width: 100%

.list
  position: absolute
  width: 100%
  height: 100%

  & > li
    font-family: "OpenSans SemiBold", sans-serif
    font-weight: 700
    font-size: 26px
    color: $primary
    text-shadow: 2px 2px 2px rgba($black, 0.3)

    &:first-child
      position: absolute
      top: 15%
      left: 48%

    &:nth-child(2)
      position: absolute
      top: 29%
      left: 55%

    &:nth-child(3)
      position: absolute
      top: 33%
      right: 10%

    &:nth-child(4)
      position: absolute
      bottom: 45%
      right: 30%

    &:nth-child(5)
      position: absolute
      top: 30%
      left: 10%

    &:nth-child(6)
      position: absolute
      bottom: 30%
      left: 44%

    &:nth-child(7)
      position: absolute
      top: 40%
      left: 20%

.textBlock
  display: flex
  align-items: center
  justify-content: center
  flex-direction: column
  padding: 30px
  text-align: center
  gap: 30px

  & > p
    text-align: center

@media (max-width: 1150px)
  .list
    & > li
      font-size: 22px

@media (max-width: 1060px)
  .list
    & > li
      font-size: 20px

@media (max-width: 992px)
  .content
    flex-direction: column

  .map
    width: 100%

  .list
    & > li
      font-size: 26px

@media (max-width: 716px)
  .list
    & > li
      font-size: 22px

@media (max-width: 600px)
  .list
    & > li
      font-size: 18px

@media (max-width: 480px)
  .list
    & > li
      font-size: 14px

@media (max-width: 386px)
  .list
    & > li
      font-size: 12px

@media (max-width: 330px)
  .list
    & > li
      font-size: 10px