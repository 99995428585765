.contacts-networks

  &_list
    @include flex
    gap: 60px
    padding: 40px 0

    &--item

    &--link
      @include flex($direction: column)
      font-family: "OpenSans SemiBold", sans-serif
      text-align: center
      color: $black
      transition: $time

      &:hover
        color: #007fea

      & span
        margin-top: 10px

    &--img
      @include size($x: 50px, $y: 50px)
      border-radius: 10px