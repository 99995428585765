.h2
  padding: 30px 0
  font-size: 26px
  font-family: "OpenSans ExtraBold", sans-serif
  text-transform: uppercase
  text-align: center
  color: $primary

.h3
  font-size: 26px
  font-family: "IBMPlex Sans", sans-serif
  text-align: center
  color: $primary

.h1
  font-family: 'IBMPlex Sans', sans-serif
  font-size: 48px
  text-transform: uppercase
  text-shadow: 1px 1px 3px $black
  color: $white
  text-align: center

.h4
  font-family: 'OpenSans SemiBold', sans-serif
  font-size: 48px
  text-shadow: 1px 1px 3px $black
  color: $white

.h5
  font-family: 'OpenSans SemiBold', sans-serif
  font-size: 28px
  color: $black

.p
  font-family: "OpenSans", sans-serif
  font-size: 22px
  color: $black
  text-align: justify

.p1
  font-family: "OpenSans", sans-serif
  font-size: 22px
  color: $black
  text-align: justify
  text-indent: 45px

.p2
  font-family: "IBMPlex Sans", sans-serif
  font-size: 18px
  color: $black

.text-list
  margin: 15px 55px
  list-style-type: disc
  text-indent: 0

@media (max-width: 568px)
  .h1
    font-size: 32px