.contacts-map
  &--frame
    @include size($y: 400px)
    border: none

  &--text
    margin-bottom: 6px
    font-family: "OpenSans", sans-serif
    line-height: 150%
    color: $black
    display: inline-block

@media (max-width: 756px)
  .contacts-map
    &--frame
      height: 250px