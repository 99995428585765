@import "../../../styles/variables"

.wrapper
  padding: 0 10px 50px 10px
  display: flex
  align-items: center
  justify-content: center
  flex-direction: column
  box-shadow: 0 0 15px rgba($black, 0.1)
  border-radius: 10px

.subtitle
  text-indent: 0
  margin-bottom: 50px

.list
  width: 100%
  margin-bottom: 70px
  display: flex
  align-items: flex-start
  justify-content: space-evenly
  flex-wrap: wrap
  gap: 20px

.listItem
  display: flex
  align-items: center
  flex-direction: column
  text-align: center
  flex-shrink: 0

.imgWrapper
  display: flex
  align-items: center
  justify-content: center
  width: 80px
  height: 80px
  padding: 20px
  border: 2px solid $primary
  border-radius: 50%
  margin-bottom: 20px

.icon
  width: 100%
  height: 100%

.text
  max-width: 240px

@media(max-width: 568px)
  .list
    gap: 50px

  .subtitle
    text-align: center

